import React, { FC } from 'react';
import clsx from 'clsx';
import { PlusMinusIcon } from '../../molecules/icons';
import { sluggify } from '../../../lib/helpers';
import './collapsible.scss';
import { SanityFaq } from '../../../../graphql-types';
import { BlockContent, Column, Row, Text } from '../..';

export type CollapsibleProps = {
  dataArray: SanityFaq[];
  initialExpanded?: number;
  expandedItems: string[];
  toggleItem: (id: string) => void;
};

export const Collapsible: FC<CollapsibleProps> = ({
  dataArray,
  expandedItems,
  toggleItem
}) => {
  const conditionsInfoList = dataArray.map((item, index) => {
    const isExpanded = expandedItems?.includes(item.id) || false;
    return (
      <div
        id={`${sluggify(item.heading)}-container`}
        key={`${item.id}-${index}`}
        className="collapsible-item"
      >
        <Row
          cols={25}
          gap={0}
          borders={['bottom', 'bottom']}
          lastRow={index === dataArray.length - 1 ? true : false}
          className="pl-sm pr-sm"
        >
          <Column>
            <div className="collapsible-title-container">
              <Text
                onClick={() => toggleItem(item.id)}
                color="dark-green"
                className={clsx(
                  'collapsible-toggle',
                  'ml-xtiny',
                  'mr-sm',
                  'mt-sm',
                  'mb-sm'
                )}
                variant="h7a"
              >
                {item.heading}
              </Text>
              <PlusMinusIcon
                onClick={() => toggleItem(item.id)}
                toggleIcon={isExpanded}
                className={clsx(
                  'collapsible-toggle',
                  'collapsible-icon',
                  'mr-tiny',
                  'mt-sm',
                  'mb-sm'
                )}
                iconWidth="22px"
              />
            </div>
            {isExpanded &&
              item._type !== 'customText' &&
              React.isValidElement(item._rawBody.customText) && (
                <Text variant="p2a" className="mb-sm">
                  <BlockContent blocks={item._rawBody} />
                </Text>
              )}
            {isExpanded && item._type === 'customText'
              ? item._rawBody.customText
              : isExpanded && (
                  <Text variant="p2a" className="mb-sm">
                    <BlockContent blocks={item._rawBody} />
                  </Text>
                )}
          </Column>
        </Row>
      </div>
    );
  });

  return (
    <div>
      <div className="full-w">{conditionsInfoList}</div>
    </div>
  );
};
